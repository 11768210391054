import React, { useMemo, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useParams } from "react-router-dom";
import { EventDetailsOverviewCards } from "./EventDetailsOverviewCards";
import { EventDetailsToolbox } from "./EventDetailsToolbox";
import { FootprintChart } from "./FootprintChart";
import { PoliciesReportsTable } from "./PoliciesReport";
import { useMonitoringEventDetail } from "../../hooks/monitoring/useMonitoringEventDetail";
import { Chip, useTheme } from "@mui/material";
import Format from "../../Util/format";
import dayjs from "dayjs";
import { convertUnit } from "../../Util/convertUnit";
import { formatNumber } from "../../Util/formatNumber";
import { useMonitoringDataCatalog } from "../../hooks/dataCatalog/useMonitoringDataCatalog";
import plural from "../../Util/pluralize";
import EventsDetailShareModal from "./EventDetailsShareModal";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
  main: {
    flex: 1,
    padding: `${theme.spacing(2.75)} ${theme.spacing(2)}`,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#f7f8fc",
  },
  header: {
    display: "flex",
    alignItems: "center",
  },
  title: {
    fontSize: 18,
    fontWeight: "bold",
    lineHeight: "20px",
  },
  chip: {
    backgroundColor: "#FFE034",
    marginLeft: theme.spacing(2),
    padding: `${theme.spacing(1.25)} ${theme.spacing(0.5)}`,
    textTransform: "uppercase",
    fontSize: 14,
    height: 38,
    borderRadius: 19,
    fontWeight: 600,
  },
  btn: {
    marginLeft: "auto",
    borderRadius: 8,
  },
  status: {
    backgroundColor: theme.palette.running,
    textTransform: "uppercase",
    fontSize: 12,
    fontWeight: 600,
    padding: "6px 14px",

    "& > .MuiChip-label": {
      padding: 0,
    },
  },
}));

const EventDetailsPage = () => {
  const classes = useStyles();
  const params = useParams();
  const theme = useTheme();
  const [pagination, setPagination] = useState({
    page: 0,
    size: 15,
  });
  const [openEventDetailsShareModal, setEventDetailsShareModal] = useState(false);
  const [eventDetailsShareLink, setEventDetailsShareLink] = useState("");
  const [isGeneratingLink, setIsGenerating] = useState(false);
  const [error, setError] = React.useState(false);
  const { data, isLoading } = useMonitoringEventDetail({
    id: params.id,
    page: pagination.page,
    size: pagination.size,
  });

  const { data: monitoringDataCatalog } = useMonitoringDataCatalog({
    dataSourceId: data?.coverage.dataSourceId,
    dataSetId: data?.coverage.dataSetId,
    monitoringTargetId: data?.coverage.monitoringTarget?.id,
  });

  const totalPayout = data?.totalPayout?.amount ?? 0;
  const totalSumInsured =
    data?.policyItems?.reduce((acc, val) => acc + (val.policyTotalSumInsured.amount ?? 0), 0) || 0;
  const isTriggered = data
    ? data.footprint.some((item) => item.indexValue >= data.coverage.trigger)
    : false;

  const handleCloseEventDetailsShareModal = () => {
    setEventDetailsShareModal(false);
  };

  const coverageStart = data?.coverage?.start;
  const coverageEnd = data?.coverage?.end;
  const isSameYear =
    coverageStart && coverageEnd ? dayjs(coverageStart).isSame(coverageEnd, "year") : false;

  const overviewCards = useMemo(() => {
    if (!data) return [];
    const symbol = data.coverage?.indexDefinition?.output?.unit?.symbol;
    return [
      {
        amount: `${Number(data.indexValue.toFixed(2)).toLocaleString() ?? 0}`,
        currency:'',
        title: `${data.coverage?.indexDefinition?.output?.name} (in  ${plural(
          convertUnit(symbol),
          Number(data.indexValue),
        )})`,
        extra: `${
          data.coverage.trigger !== null
            ? `Trigger: ${data.coverage.trigger} ${plural(
                convertUnit(symbol),
                Number(data.coverage.trigger),
              )}`
            : ""
        }${
          data.coverage.exit !== null
            ? `, Exit: ${data.coverage.exit} ${plural(
                convertUnit(symbol),
                Number(data.coverage.exit),
              )}`
            : ""
        }`,
        extraColor: isTriggered ? theme.palette.indicatorRed : theme.palette.indicatorGreen,
      },
      {
        amount: formatNumber(totalPayout.toFixed(1), data?.totalPayout?.currency),
        currency: data?.totalPayout?.currency,
        title: `Total Payout in ${data?.totalPayout?.currency}`,
        extra: `${Format.percentageWrapFormat(data?.payoutPct ?? 0)} of TSI`,
        extraColor: theme.palette.indicatorRed,
      },
      {
        amount: formatNumber(data?.insuredExtendValue.toFixed(1) ?? 0),
        title: `Insured extent (in ${plural(convertUnit(data.insuredExtendUnit), Number(data?.insuredExtendValue))})`,
        extra: "",
        extraColor: theme.palette.indicatorGreen,
      },
      {
        amount: formatNumber(data?.policyItems[0].coverageTotalSumInsured?.amount.toFixed(1) ?? 0, data?.policyItems[0].coverageTotalSumInsured?.currency ),
        currency: data?.policyItems[0].coverageTotalSumInsured?.currency,
        title: `TSI in ${data?.policyItems[0].coverageTotalSumInsured?.currency}`,
        extra: `${
          coverageStart ? dayjs(coverageStart).format(isSameYear ? "DD-MMM" : "DD-MMM-YYYY") : ""
        } to ${
          coverageEnd ? dayjs(coverageEnd).format(isSameYear ? "DD-MMM YYYY" : "DD-MMM-YYYY") : ""
        }`,
        extraColor: theme.palette.indicatorGreen,
      },
    ];
  }, [
    coverageEnd,
    coverageStart,
    data,
    isSameYear,
    isTriggered,
    theme.palette.indicatorGreen,
    theme.palette.indicatorRed,
    totalPayout,
    totalSumInsured,
  ]);

  return (
    <Box className={classes.root}>
      <Toolbar />
      <EventDetailsToolbox
        recipients={[]}
        coverageId={data?.coverage?.id}
        setEventDetailsShareModal={setEventDetailsShareModal}
        monitoringId={params.id}
        setEventDetailsShareLink={setEventDetailsShareLink}
        setError={setError}
        setIsGenerating={setIsGenerating}
      />
      <Box className={classes.main}>
        <Box className={classes.header}>
          <Stack direction="row" spacing={2} alignItems="center">
            {data && (
              <Typography className={classes.title}>
                {data?.coverage?.triggerLabel + " for " + data?.coverage?.label}
              </Typography>
            )}
            {data?.status && (
              <Chip
                className={classes.status}
                label={data.status}
                sx={{ backgroundColor: "#FFE034", textTransform: "uppercase" }}
              />
            )}
          </Stack>
        </Box>
        {data && !isLoading && (
          <Stack gap={3.75}>
            <EventDetailsOverviewCards overviewCards={overviewCards} isLoading={isLoading} />
            <FootprintChart
              data={data.footprint}
              isLoading={isLoading}
              riskTypeId={data.coverage.riskTypeId}
              name={data.coverage.indexDefinition.description}
              trigger={data.coverage.trigger}
              exit={data.coverage.exit}
              unit={convertUnit(data.coverage?.indexDefinition?.output?.unit?.symbol)}
              lowerChartTitle={data.coverage.indexDefinition.output.name}
              minPayout={data.coverage.minPayout}
              payoutPerUnit={data.coverage.payoutPerUnit}
              triggers={data.coverage.indexDefinition.triggers}
              monitoringDataCatalog={monitoringDataCatalog}
              radius={data?.coverage.indexDefinition.triggers[0]?.value ?? 0}
              triggerComporator={data?.coverage.indexDefinition.triggerComparator}
            />
            <PoliciesReportsTable
              policies={data.policyItems}
              pagination={pagination}
              setPagination={setPagination}
            />
          </Stack>
        )}
        {!data && !isLoading && <Typography>No data</Typography>}
        {isLoading && <Typography>Loading</Typography>}
      </Box>
      <EventsDetailShareModal
        open={openEventDetailsShareModal}
        onClose={handleCloseEventDetailsShareModal}
        value={eventDetailsShareLink}
        error={error}
        isGeneratingLink = {isGeneratingLink}
      />
    </Box>
  );
};

export default EventDetailsPage;
