import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ContextTheme from '../../Contexts/Theme';
import Time from '../../Util/time';
import String from '../../Util/string';
import Format from '../../Util/format';
import Button from '@mui/material/Button';
import { useReactToPrint } from "react-to-print";

// Icons
import FolderIcon from '@mui/icons-material/Folder';
import CardIcon from '../Cards/CardIcon';
import formatMissingValue from '../../Util/formatMissingValue';
import Ajax, { GetToken } from '../../Util/ajax';
import PrintIcon from '@mui/icons-material/Print';
import { IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import clsx from 'clsx';
import { usePolicyReport } from '../../hooks/policy';
import { formatNumber } from '../../Util/formatNumber';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
  },
  card: {
    position: 'relative',
    padding: theme.spacing(2.5),
    backgroundColor: theme.palette.common.white,
    minHeight: 100,
    height: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 12,
    boxShadow: '0px 3px 12px 0px #0000001F',
    flex: 1,
  },
  cardItem: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > *': {
      padding: '8px 18px',
    },
  },
  divider: {
    opacity: 0.15,
  },
  downloadButton: {
    borderRadius: 8,
    borderColor: theme.palette.text.hint,
    color: theme.palette.text.secondary,
  },
  disclaimer: {
    textAlign: "center",
    padding: 16,
    fontWeight: 600,
    fontSize: 16,
  },
  note: {
    textAlign: 'center',
    fontSize: '14px',
    marginBottom: 8,
  },
  table: {
    borderCollapse: 'collapse',
    marginTop: 16,
  },
  tableCell: {
    border: `2px solid ${theme.palette.starDust}`,
  },
  tableValue: {
    backgroundColor: theme.palette.seaShell,
  },
  tableHeading: {
    backgroundColor: theme.palette.greyGoose,
  },
  indexTracker: {
    color: theme.palette.blueDress,
  },
  indexDefinition: {
    color: theme.palette.blackCow,
  }
}));

const formatCurrency = (currency, amount) =>
  amount ? `${currency} ${formatNumber(amount, currency)}` : '---';

const PolicyStack = (props) => {
  const {
    customerName,
    inceptionDate,
    expiryDate,
    grossWrittenPremium,
    coverages,
    title,
    status,
    policyScheduleDocumentId,
    loaded,
    onDownload,
    currency,
    issuer,
    description,
    intermediary,
    lossRatio,
    totalCalculatedPayoutAmount,
    grossWrittenPremiumPct
  } = props;
  const root = window.appConfig.apiUrl;
  const { locale } = useContext(ContextTheme);
  const classes = useStyles();
  const totalSumInsured = coverages?.reduce((acc, val) => acc + val.totalSumInsured?.amount, 0) ?? 0;
  const params = useParams();
  const [isPrinting, setIsPrinting] = useState(false);
  const componentRef = useRef(null);
  const promiseResolveRef = useRef(null);
  const { data: reports = [] } = usePolicyReport(params.id);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () => {
      return new Promise((resolve) => {
        promiseResolveRef.current = resolve;
        setIsPrinting(true);
      });
    },
    onAfterPrint: () => {
      promiseResolveRef.current = null;
      setIsPrinting(false);
    },
    documentTitle: title,

  });

  const downloadCsvReport = async (triggerLabel) => {
    const token = await GetToken();
    const response = await Ajax.getData(
      `${window.appConfig.apiUrl}/internal/policies/${params.id}/footprint/report?format=csv`,
      token,
      { responseType: "blob" },
    );

    const url = URL.createObjectURL(response);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${title} - ${triggerLabel}`;
    a.click();
  };

  useEffect(() => {
    if (isPrinting && promiseResolveRef.current) {
      promiseResolveRef.current();
    }
  }, [isPrinting]);

  const coverageContent = coverages.length ? (
    coverages.map((coverage, index) => {
      const {
        id,
        indexDefinition,
        cohortName,
        sumInsuredIndividual,
        trigger,
        minPayout,
        minPayoutPct,
        totalSumInsured,
        payoutPerUnitPct,
        pricingUnit,
        quantity,
        dataSourceDescription,
        triggerLabel,
        totalPayoutPerUnit,
      } = coverage;

      const coveragesLen = coverages?.length;
      const calculatedMinPayout = minPayout?.amount * 0.01 * sumInsuredIndividual?.amount * quantity;

      return (
        <div key={id}>
          <div className={classes.cardItem}>
            <Typography>
              <Box component="span" fontWeight="fontWeightBold">
                {indexDefinition?.name}
              </Box>
            </Typography>
            {/* {!isPrinting && (
              <Button
                variant="outlined"
                className={classes.downloadButton}
                onClick={() => downloadCsvReport(triggerLabel)}
              >
                Download
              </Button>
            )} */}
          </div>
          <div className={classes.cardItem}>
            <Typography>Cohort name</Typography>
            <Typography align="right">{cohortName || "---"}</Typography>
          </div>
          <div className={classes.cardItem}>
            <Typography>Trigger</Typography>
            <Typography align="right">{trigger || "---"}</Typography>
          </div>
          <div className={classes.cardItem}>
            <Typography>Sum Insured Per Insured Extend</Typography>
            <Typography align="right">
              {formatCurrency(sumInsuredIndividual.currency, sumInsuredIndividual.amount)}
            </Typography>
          </div>
          <div className={classes.cardItem}>
            <Typography>Insured Extend</Typography>
            <Typography align="right">{Format.number(quantity) || "---"}</Typography>
          </div>
          <div className={classes.cardItem}>
            <Typography>Sum Insured</Typography>
            <Typography align="right">
              {formatCurrency(totalSumInsured.currency, totalSumInsured.amount)}
            </Typography>
          </div>
          <div className={classes.cardItem}>
            <Typography>Minimum Payout</Typography>
            <Typography align="right">
              ({minPayoutPct * 100}%){" "}
              {formatCurrency(minPayout.currency, calculatedMinPayout)}
            </Typography>
          </div>
          <div className={classes.cardItem}>
            <Typography>Payout per {pricingUnit}</Typography>
            <Typography align="right">
              ({Number((payoutPerUnitPct ?? 0) * 100)?.toFixed(2)}%){" "}
              {formatCurrency(currency, totalPayoutPerUnit?.amount)}
            </Typography>
          </div>
          <div className={classes.cardItem}>
            <Typography>Data Source</Typography>
            <Typography align="right">{formatMissingValue(dataSourceDescription)}</Typography>
          </div>
          {/* Render horizontal rule only if not last item*/}
          {coveragesLen - 1 !== index ? <hr className={classes.divider} /> : null}
        </div>
      );
    })
  ) : (
    <div className={classes.cardItem}>
      <Typography>No coverages</Typography>
    </div>
  );

  const coverageContentForPrinting = coverages.length ? (
    coverages.map((coverage) => {
      const {
        coverageId,
        indexDefinition,
        description,
        start,
        end,
        trigger,
        dataSourceDescription,
        payoutPerUnitPct,
        sumInsured,
        totalSumInsured,
        payoutPerUnit,
      } = coverage;

      const report = reports.find(item => item.coverageId === coverageId);

      return (
        <Table key={coverageId} className={classes.table}>
          <TableBody>
            <TableRow>
              <TableCell className={clsx(classes.tableCell, classes.tableHeading)} colSpan={4}>
                <Stack
                  spacing={2}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Stack spacing={1} direction="row" alignItems="center">
                    <Typography whiteSpace="nowrap" variant="h6" fontWeight="fontWeightBold">
                      {description}
                    </Typography>
                    <Typography
                      variant="body2"
                      fontWeight={500}
                      className={classes.indexDefinition}
                    >
                      {/*({indexDefinition?.name})*/}
                    </Typography>
                  </Stack>
                  {report?.eventId && (
                    <Typography whiteSpace="nowrap" fontWeight={500}>
                      <Box
                        as="a"
                        flexShrink={0}
                        href={`/monitoring/event/${report?.eventId}`}
                        className={classes.indexTracker}
                      >
                        Index Tracker
                      </Box>
                    </Typography>
                  )}
                </Stack>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head" colSpan={1} width="20%" className={classes.tableCell}>
                <Typography>Data Source</Typography>
              </TableCell>
              <TableCell colSpan={3} width="80%" className={classes.tableCell}>
                <Typography fontWeight="700">
                  {formatMissingValue(dataSourceDescription)}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head" className={classes.tableCell} colSpan={1} width="20%">
                <Typography>Index definition</Typography>
              </TableCell>
              <TableCell
                  className={clsx(classes.tableCell, classes.tableValue)}
                  colSpan={3}
                  width="80%"
              >
                <Typography fontWeight="700">
                  {formatMissingValue(indexDefinition?.name)}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head" className={classes.tableCell} colSpan={1} width="20%">
                <Typography>Reporting Period</Typography>
              </TableCell>
              <TableCell
                className={clsx(classes.tableCell, classes.tableValue)}
                colSpan={3}
                width="80%"
              >
                <Typography fontWeight="700">
                  {Time.getDate(start, locale)} to {Time.getDate(end, locale)}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head" className={classes.tableCell} colSpan={1} width="20%">
                <Typography>Trigger</Typography>
              </TableCell>
              <TableCell
                  className={clsx(classes.tableCell, classes.tableValue)}
                  colSpan={3}
                  width="80%"
              >
                <Typography fontWeight="700">
                  {formatMissingValue(trigger)}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head" colSpan={1} width="20%" className={classes.tableCell}>
                <Typography>Index Value</Typography>
              </TableCell>
              <TableCell colSpan={3} width="80%" className={classes.tableCell}>
                <Typography fontWeight="700">
                  {report?.indexValue
                      ? Number(report?.indexValue)?.toFixed(1)   + " " + indexDefinition.output.unit.symbol
                      : formatMissingValue(report?.indexValue)}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head" colSpan={1} width="20%" className={classes.tableCell}>
                <Typography>Payout per unit</Typography>
              </TableCell>
              <TableCell colSpan={3} width="80%" className={classes.tableCell}>
                <Typography fontWeight="700">
                  {`(${(Number(payoutPerUnitPct) * 100)?.toFixed(1)}%) ${formatCurrency(
                      currency,
                      payoutPerUnit?.amount,
                  )}`}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head" className={classes.tableCell} colSpan={1} width="20%">
                <Typography>Sum Insured per area insured</Typography>
              </TableCell>
              <TableCell
                  className={clsx(classes.tableCell, classes.tableValue)}
                  colSpan={3}
                  width="80%"
              >
                <Typography fontWeight="700">
                  {sumInsured ? formatCurrency(
                      sumInsured.currency,
                      sumInsured?.amount,
                  ): "---"}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head" className={classes.tableCell} colSpan={1} width="20%">
                <Typography>Sum Insured</Typography>
              </TableCell>
              <TableCell
                  className={clsx(classes.tableCell, classes.tableValue)}
                  colSpan={3}
                  width="80%"
              >
                <Typography fontWeight="700">
                  {totalSumInsured ? formatCurrency(
                      totalSumInsured?.currency,
                      totalSumInsured?.amount,
                  ): "---"}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head" className={classes.tableCell} colSpan={1} width="20%">
                <Typography>Payout per area insured</Typography>
              </TableCell>
              <TableCell
                  className={clsx(classes.tableCell, classes.tableValue)}
                  colSpan={3}
                  width="80%"
              >
                <Typography fontWeight="700">
                  {`${formatCurrency(
                      currency,
                      report?.calculatedPayout?.amount / coverages[0]?.quantity,
                  )}`}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head" colSpan={1} width="20%" className={classes.tableCell}>
                <Typography>Total Payout</Typography>
              </TableCell>
              <TableCell colSpan={3} width="80%" className={classes.tableCell}>
                <Typography fontWeight="700">
                  {report?.calculatedPayout
                    ? formatCurrency(
                        report.calculatedPayout?.currency,
                        report.calculatedPayout?.amount,
                      )
                    : "---"}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      );
    })
  ) : (
    <Typography textAlign="center">No coverages</Typography>
  );

  const content = (
    <>
      <Grid container spacing={4}>
        <Grid xs={12} lg={6}>
          <Typography variant="h5" className={classes.title}>
            Basic Information
          </Typography>
          <Stack sx={{ mt: 4 }} rowGap={2.5}>
            <Grid container>
              <Grid sm={5}>
                <Typography variant="body1">Policy Name</Typography>
              </Grid>
              <Grid sm={7}>
                <Typography variant={"body1"} fontWeight={600}>
                  {formatMissingValue(title)}
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid sm={5}>
                <Typography variant="body1">Policy Issuer</Typography>
              </Grid>
              <Grid sm={7}>
                <Typography variant="body1" fontWeight={600}>
                  {formatMissingValue(issuer)}
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid sm={5}>
                <Typography variant="body1">Policy Holder Name</Typography>
              </Grid>
              <Grid sm={7}>
                <Typography variant="body1" fontWeight={600}>
                  {formatMissingValue(customerName)}
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid sm={5}>
                <Typography variant="body1">Intermediary</Typography>
              </Grid>
              <Grid sm={7}>
                <Typography variant="body1" fontWeight={600}>
                  {formatMissingValue(intermediary)}
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid sm={5}>
                <Typography variant="body1">Inception Date</Typography>
              </Grid>
              <Grid sm={7}>
                <Typography variant="body1" fontWeight={600}>
                  {inceptionDate ? Time.getDate(inceptionDate, locale) : "---"}
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid sm={5}>
                <Typography variant="body1">Expiry Date</Typography>
              </Grid>
              <Grid sm={7}>
                <Typography variant="body1" fontWeight={600}>
                  {expiryDate ? Time.getDate(expiryDate, locale) : "---"}
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid sm={5}>
                <Typography variant="body1">Status</Typography>
              </Grid>
              <Grid sm={7}>
                <Typography variant="body1" fontWeight={600}>
                  {String.capitalize(status) || "---"}
                </Typography>
              </Grid>
            </Grid>
          </Stack>
        </Grid>
        <Grid xs={12} lg={6}>
          <Typography variant="h5" className={classes.title}>
            Description
          </Typography>
          <Stack sx={{ mt: 4 }} rowGap={2.5}>
            {description
              ?.split("\n")
              .filter(Boolean)
              .map((item) => {
                const [key, val] = item.split(":");

                return (
                  <Grid container key={key}>
                    <Grid sm={5}>
                      <Typography variant="body1">{key}</Typography>
                    </Grid>
                    <Grid sm={7}>
                      <Typography variant="body1" fontWeight={600}>
                        {formatMissingValue(val)}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
          </Stack>
        </Grid>
        <Grid xs={12} lg={6}>
          <Typography variant="h5" className={classes.title}>
            Premiums
          </Typography>
          <Stack sx={{ mt: 4 }} rowGap={2.5}>
            <Grid container>
              <Grid sm={5}>
                <Typography variant="body1">Premium Amount</Typography>
              </Grid>
              <Grid sm={7}>
                <Typography variant="body1" fontWeight={600}>
                  {formatMissingValue(grossWrittenPremium)}
                </Typography>
              </Grid>
            </Grid>
            {/*<Grid container>*/}
            {/*  <Grid sm={5}>*/}
            {/*    <Typography variant="body1">Average Premium Rate (%)</Typography>*/}
            {/*  </Grid>*/}
            {/*  <Grid sm={7}>*/}
            {/*    <Typography variant="body1" fontWeight={600}>*/}
            {/*      {avgGrossWrittenPremiumPct && `${avgGrossWrittenPremiumPct}`}*/}
            {/*    </Typography>*/}
            {/*  </Grid>*/}
            {/*</Grid>*/}
            <Grid container>
              <Grid sm={5}>
                <Typography variant="body1">Loss Ratio (%)</Typography>
              </Grid>
              <Grid sm={7}>
                <Typography variant="body1" fontWeight={600}>
                  {lossRatio && `${lossRatio}`}
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid sm={5}>
                <Typography variant="body1">Total Claim amount</Typography>
              </Grid>
              <Grid sm={7}>
                <Typography variant="body1" fontWeight={600}>
                  {totalCalculatedPayoutAmount && totalCalculatedPayoutAmount}
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid sm={5}>
                <Typography variant="body1">Premium Rate (%)</Typography>
              </Grid>
              <Grid sm={7}>
                <Typography variant="body1" fontWeight={600}>
                  {grossWrittenPremiumPct}
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid sm={5}>
                <Typography variant="body1">Total Sum Insured</Typography>
              </Grid>
              <Grid sm={7}>
                <Typography variant="body1" fontWeight={600}>
                  {formatCurrency(currency, totalSumInsured)}
                </Typography>
              </Grid>
            </Grid>
          </Stack>
        </Grid>
        <Grid xs={12}>
          <Typography variant="h5" className={classes.title}>
            Policy Details
          </Typography>
          <Stack sx={{ mt: 2.5 }}>{coverageContent}</Stack>
        </Grid>
      </Grid>
    </>
  );

  const informationForPrinting = [
    [
      { value: "Policy Issuer", isHead: true },
      { value: formatMissingValue(issuer), isHead: false },
      { value: "Inception Date", isHead: true },
      { value: inceptionDate ? Time.getDate(inceptionDate, locale) : "---", isHead: false },
    ],
    [
      { value: "Quantity / area insured", isHead: true },
      { value: formatMissingValue(coverages[0]?.quantity), isHead: false },
      { value: "Total SI", isHead: true },
      { value: formatCurrency(currency, totalSumInsured), isHead: false },
    ],
    [
      { value: "Policy Holder Name", isHead: true },
      { value: formatMissingValue(customerName), isHead: false },
      { value: "Expiry Date", isHead: true },
      { value: expiryDate ? Time.getDate(expiryDate, locale) : "---", isHead: false },
    ],
    [
      { value: "Intermediary", isHead: true },
      { value: formatMissingValue(intermediary), isHead: false },
      { value: "Status", isHead: true },
      { value: String.capitalize(status) || "---", isHead: false },
    ],
  ];

  const contentForPrinting = (
    <>
      <Typography variant="h4" fontWeight={700} textAlign="center">
        {formatMissingValue(title)}
      </Typography>
      <Typography variant="body1" textAlign="center" mt={0.5} mb={0.5}>
        Data Monitoring Report
      </Typography>
      <Table className={classes.table}>
        <TableBody>
          {informationForPrinting.map((row, index) => (
            <TableRow key={index}>
              {row.map((cell, cellIndex) => (
                <TableCell
                  key={"cell" + cellIndex}
                  {...(cell.isHead && { variant: "head" })}
                  width={cell.isHead ? "20%" : "30%"}
                  className={clsx(classes.tableCell, !cell.isHead && classes.tableValue)}
                >
                  <Typography {...(!cell.isHead && { fontWeight: 700 })}>{cell.value}</Typography>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell
              variant="head"
              colSpan={4}
              className={clsx(classes.tableCell, classes.tableHeading)}
            >
              <Typography
                textAlign="center"
                variant="h6"
                textTransform="uppercase"
                fontWeight="fontWeightBold"
              >
                Policy Details
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {description
            ?.split("\n")
            .filter(Boolean)
            .map((row, index) => {
              const [key, value] = row.split(":");

              return (
                <TableRow key={index}>
                  <TableCell variant="head" width="20%" className={classes.tableCell}>
                    <Typography>{key}</Typography>
                  </TableCell>
                  <TableCell width="80%" className={clsx(classes.tableCell, classes.tableValue)}>
                    <Typography fontWeight={700}>{value}</Typography>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      {coverageContentForPrinting}
      <Table className={classes.table}>
        <TableHead>
          <TableCell colSpan={4} className={clsx(classes.tableCell, classes.tableHeading)}>
            <Typography
              textAlign="center"
              variant="h6"
              textTransform="uppercase"
              fontWeight="fontWeightBold"
            >
              Premiums
            </Typography>
          </TableCell>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell variant="head" width="20%" className={classes.tableCell}>
              <Typography>Premium Amount</Typography>
            </TableCell>
            <TableCell width="30%" className={clsx(classes.tableCell, classes.tableValue)}>
              <Typography fontWeight="700">{formatMissingValue(grossWrittenPremium)}</Typography>
            </TableCell>
            <TableCell variant="head" width="20%" className={classes.tableCell}>
              <Typography>Premium Rate (%)</Typography>
            </TableCell>
            <TableCell width="30%" className={clsx(classes.tableCell, classes.tableValue)}>
              <Typography fontWeight="700">
                {grossWrittenPremiumPct}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head" width="20%" className={classes.tableCell}>
                {/*<Typography>Average Premium Rate (%)</Typography>*/}
              </TableCell>
              <TableCell width="30%" className={clsx(classes.tableCell, classes.tableValue)}>
                {/*<Typography fontWeight="700">{formatMissingValue(avgGrossWrittenPremiumPct)}</Typography>*/}
              </TableCell>
              <TableCell variant="head" width="20%" className={classes.tableCell}>
                <Typography>Loss Ratio (%)</Typography>
              </TableCell>
              <TableCell width="30%" className={clsx(classes.tableCell, classes.tableValue)}>
                <Typography fontWeight="700">{formatMissingValue(lossRatio)}%</Typography>
              </TableCell>
          </TableRow>
          <TableRow>
              <TableCell variant="head" width="20%" className={classes.tableCell}>
                <Typography>Total Claim amount</Typography>
              </TableCell>
              <TableCell width="30%" className={clsx(classes.tableCell, classes.tableValue)}>
                <Typography fontWeight="700">{formatMissingValue(totalCalculatedPayoutAmount)}</Typography>
              </TableCell>
              <TableCell variant="head" width="20%" className={classes.tableCell}>
                <Typography>Total Sum Insured</Typography>
              </TableCell>
              <TableCell width="30%" className={clsx(classes.tableCell, classes.tableValue)}>
                <Typography fontWeight="700">{formatCurrency(currency, totalSumInsured)}</Typography>
              </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Box mt={4}>
        <Typography className={classes.disclaimer}>
          THIS IS NOT A CLAIMS MONITORING REPORT. INTENDED TO BE USED FOR CONFIDENTIAL PURPOSES ONLY
        </Typography>
      </Box>
    </>
  );

  const getPageMargins = () => {
    return `@page { margin: 16px 0;  }`;
  };

  return (
    <Container className={classes.root} maxWidth="xl">
      <Stack alignItems="flex-end" mb={2}>
        <IconButton onClick={handlePrint} className={classes.printButton}>
          <PrintIcon />
        </IconButton>
      </Stack>
      <Stack spacing={2}>
        <Box className={classes.card} ref={componentRef}>
          {isPrinting ? contentForPrinting : content}
        </Box>
        <CardIcon
          loaded={loaded}
          title="Policy schedule"
          reportId={policyScheduleDocumentId}
          handleDownload={onDownload}
          linkUrl={`${root}/internal/files/${policyScheduleDocumentId}`}
          icon={<FolderIcon />}
        >
          Coverage contains a loss and risk breakdown of a coverage before creating a quotation.
        </CardIcon>
      </Stack>
      <style>{getPageMargins()}</style>
    </Container>
  );
};

export default PolicyStack;
