import abbreviate from "number-abbreviate";
import { formatIndianNumber } from "./formatIndianNumber";

export const formatNumber = (num, currency = "") => {
  if (num === null || currency === null) {
    return 0
  }
  const numberValue = Number(num);
  if (currency.toUpperCase() === "INR") {
    return formatIndianNumber(numberValue);
  }
  
  // For small numbers (less than 1000), show 2 decimal places
  if (numberValue < 1000) {
    return Number(numberValue.toFixed(2));
  }
  
  // For larger numbers, use abbreviation
  return abbreviate(numberValue, 2);
};
