import React, { useEffect, useMemo, useRef, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighChartsMore from "highcharts/highcharts-more";
import applyRoundedCorners from 'highcharts-border-radius';
import { useTheme } from "@mui/material";
import dayjs from "dayjs";
import formatMissingValue from "../../Util/formatMissingValue";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { convertUnit } from "../../Util/convertUnit";
import maplibre from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import { point, polygon, booleanPointInPolygon, circle } from "@turf/turf";
import plural from "../../Util/pluralize";
import clsx from "clsx";
import useWindowResize from "../../hooks/utils/useWindowResize";
import { useSidebarContext } from "../../Contexts/SidebarContext";

dayjs.extend(advancedFormat);

HighChartsMore(Highcharts);
applyRoundedCorners(Highcharts);

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 12,
    boxShadow: "0px 3px 12px 0px #0000001F",
    padding: theme.spacing(2),
    backgroundColor: "white",
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontSize: 18,
    fontWeight: 700,
    color: "#1A1A21",
    marginBottom: theme.spacing(9),
  },
  map: {
    flex: 1,
    width: "100%",
    height: 600,
    marginBottom: 10,
    display: "none",
  },
  mapShow: {
    display: "block",
    position: "relative",
  },
  popup: {
    background: "rgba(0, 0, 0, 0.7)",
    padding: "10px",
    borderBottom: "2px solid #fff",
    color: "#fff",
    position: "relative",
  },
  coordinates: {
    fontWeight: 500,
    marginBottom: 4,
  },
  date: {
    fontWeight: 500,
    marginBottom: 4,
  },
  measurement: {
    fontWeight: 500,
    whiteSpace: "nowrap",
  },
  measurementName: {
    textTransform: "uppercase",
  },
}));

Highcharts.Point.prototype.highlight = function (event) {
  event = this.series.chart.pointer.normalize(event);
  this.series.chart.tooltip.refresh(this);
  this.series.chart.xAxis[0].drawCrosshair(event, this);
};

function syncExtremes(e) {
  const thisChart = this.chart;

  if (e.trigger !== "syncExtremes") {
    // Prevent feedback loop
    Highcharts.each(Highcharts.charts, function (chart) {
      if (chart !== thisChart) {
        if (chart.xAxis[0].setExtremes) {
          // It is null while updating
          chart.xAxis[0].setExtremes(e.min, e.max, undefined, false, { trigger: "syncExtremes" });
        }
      }
    });
  }
}

export const RainfallChart = ({ data, title, triggers, containerWidth }) => {
  const label = data.find(({variable}) => variable.name)?.variable?.name ?? "Rainfall";
  const theme = useTheme();
  const transformedMeasurements = useMemo(() => {
    return data?.map((item) => {
      return {
        totalPrecipitation: item.measurements[0],
        timestampUtc: item.timestampUtc,
      };
    });
  }, [data]);

  const seriesData = transformedMeasurements?.map((measurement) => ({
    x: new Date(measurement.timestampUtc).getTime(),
    y: Number(measurement.totalPrecipitation.value.toFixed(2)),
    unit: plural(convertUnit(measurement?.totalPrecipitation?.variable?.unit.symbol),Number(measurement.totalPrecipitation.value.toFixed(2))),
  }));

  const minDate = Math.min(...seriesData?.map(({x}) => x));
  const maxDate = Math.max(...seriesData?.map(({x}) => x));
  const days = Math.ceil((maxDate - minDate) / (24 * 3600 * 1000));
  const MAX_DAYS = 90;
  const chartWidth = containerWidth * days / MAX_DAYS;

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={{
        chart: {
          type: "column",
          height: 400,
          spacingBottom: 100,
          scrollablePlotArea: {
            minWidth: chartWidth,
            scrollPositionX: 0
          }
        },
        plotOptions: {
          column: {
            pointWidth: 9,
            borderRadius: 0,
          },
          series: {
            minPointLength: 2,
            dataLabels: {
              enabled: true,
              zIndex: 11,
            },
          },
        },
        tooltip: {
          crosshairs: true,
          formatter: function () {
            return [
              `<span style="font-size: 0.8em">${dayjs(this.x).format(
                "dddd, DD MMM YYYY",
              )}</span><br/>`,
              `<span style="color:#2caffe">●</span> ${label}: <b>${this.y} ${convertUnit(
                this.point?.unit,
              )}</b><br/>`,
              "",
            ];
          },
          outside: true,
        },
        xAxis: {
          type: "datetime",
          gridLineWidth: 0,
          crosshair: true,
          events: {
            setExtremes: syncExtremes,
          },
          lineWidth: 0,
          tickWidth: 0,
          tickInterval: 24 * 3600 * 1000, // Tick interval of one day
          labels: {
            formatter: function () {
              const value = seriesData.find((item) => item.x === this.pos);
              const currentDate = dayjs(this.pos).format("DD/MM");

              if (value === undefined) {
                return;
              }

              let isTriggered = false;

              triggers.forEach((trigger) => {
                if (isTriggered) {
                  return;
                }

                if (
                  (trigger?.triggerComparator === "GT" && value.y > trigger?.value) ||
                  (trigger?.triggerComparator === "LT" && value.y < trigger?.value)
                ) {
                  isTriggered = true;
                }
              });

              return `<span ${isTriggered ? `style="color: red"` : ""}>${currentDate}</span>`;
            },
            rotation: -90,
          },
        },
        yAxis: {
          gridLineColor: theme.palette.mercury,
          gridLineWidth: 1,
          title: {
            enabled: false,
          },
          labels: {
            style: {
              color: theme.palette.liver,
              fontWeight: 400,
              fontSize: 12,
            },
          },
          plotLines: [
            {
              value: triggers[0]?.value ?? null,
              color: theme.palette.indicatorRed,
              width: 1,
              zIndex: 10,
              dashStyle: "longdashdot",
            },
          ],
        },
        title: {
          text: title,
          align: "left",
          style: {
            fontWeight: 400,
            fontSize: 10,
          },
        },
        series: [
          {
            name: label,
            data: seriesData,
          },
        ],
        legend: {
          y: 70,
        },
      }}
    />
  );
};

export const TemperatureChart = ({ data, triggers }) => {
  const label = data ? data[0]?.measurementLabel : "Temperature";
  const theme = useTheme();
  const transformedMeasurements = useMemo(() => {
    return data?.map((item) => {
      return {
        tmin: item.measurements[0],
        tmax: item.measurements[1],
        timestampUtc: item.timestampUtc,
      };
    });
  }, [data]);

  const seriesData = transformedMeasurements?.map((measurement) => ({
    x: new Date(measurement.timestampUtc).getTime(),
    low: Number(measurement.tmin.value.toFixed(2)),
    high: Number(measurement.tmax.value.toFixed(2)),
    unit:
      plural(
        convertUnit(measurement?.tmax?.variable?.unit.symbol),
        Number(measurement.tmin.value.toFixed(2)),
      ) ??
      plural(
        convertUnit(measurement?.tmin?.variable?.unit.symbol),
        Number(measurement.tmax.value.toFixed(2)),
      ),
  }));

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={{
        chart: {
          type: "columnrange",
          height: 400,
          spacingBottom: 100,
        },
        rangeSelector: {
          selected: 2,
        },
        plotOptions: {
          columnrange: {
            pointWidth: 9,
          },
          series: {
            minPointLength: 2,
            dataLabels: {
              enabled: true,
              zIndex: 11,
            },
          },
        },
        tooltip: {
          outside: true,
          crosshairs: true,
          formatter: function () {
            return [
              `<span style="font-size: 0.8em">${dayjs(this.x).format(
                "dddd, DD MMM YYYY",
              )}</span><br/>`,
              `<span style="color:#2caffe">●</span> ${label}: <b>${this.y} ${this.point.unit}</b><br/>`,
              "",
            ];
          },
        },
        xAxis: {
          type: "datetime",
          gridLineWidth: 0,
          crosshair: true,
          events: {
            setExtremes: syncExtremes,
          },
          lineWidth: 0,
          tickWidth: 0,
          tickInterval: 24 * 3600 * 1000, // Tick interval of one day
          labels: {
            formatter: function () {
              const value = seriesData.find((item) => item.x === this.pos);
              const currentDate = dayjs(this.pos).format("DD/MM");

              if (value === undefined) {
                return;
              }

              let isTriggered = false;

              triggers.forEach((trigger) => {
                if (isTriggered) {
                  return;
                }

                if (
                  (trigger?.triggerComparator === "GT" && value?.high > trigger?.value) ||
                  (trigger?.triggerComparator === "LT" && value?.low < trigger?.value)
                ) {
                  isTriggered = true;
                }
              });

              return `<span ${isTriggered ? `style="color: red"` : ""}>${currentDate}</span>`;
            },
            rotation: -90,
          },
        },
        yAxis: {
          gridLineColor: theme.palette.mercury,
          gridLineWidth: 1,
          title: {
            enabled: false,
          },
          labels: {
            style: {
              color: theme.palette.liver,
              fontWeight: 400,
              fontSize: 12,
            },
          },
          plotLines: triggers.map((item) => ({
            value: item?.value ?? null,
            color: theme.palette.indicatorRed,
            width: 1,
            zIndex: 10,
            dashStyle: "longdashdot",
          })),
        },
        title: {
          text: "Daily min/max temp",
          align: "left",
          style: {
            fontWeight: 400,
            fontSize: 10,
          },
        },
        series: [
          {
            name: label,
            data: seriesData,
          },
        ],
        legend: {
          enabled: false,
        },
      }}
    />
  );
};

const SYNC_EVENTS = ["mousemove", "touchmove", "touchstart"];

const PRIMARY_COLOR = "#dc2626";
const SECONDARY_COLOR = "#ffe034";
const GRAY_COLOR = "#ddd";
const ORANGE_COLOR = "#f97316";
const BLUE_COLOR = "#0284c7";
const GREEN_COLOR = "#16a34a";

const WindfallChart = ({ title, trigger, data, monitoringDataCatalog, radius, exit }) => {
  const mapContainer = useRef(null);
  const maplibreRef = useRef();
  const classes = useStyles();
  const pointRef = useRef(null);
  const mouseOutRef = useRef(null);
  const [pointsInPolygon, setPointsInPolygon] = useState([]);
  const [highlightedPoints, setHighlightedPoints] = useState(null);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const theme = useTheme();
  const transformedData = data?.reduce((acc, val) => {
    val.measurements.forEach((measurement) => {
      const feature = val.features?.features?.find(
        (feat) => feat.properties?.referenceId === measurement?.referenceId,
      );
      const isRed = val.payout > 0;
      const isOrange = val.payout === 0 && feature?.properties?.intersected === 1;
      const isGreen = val.payout === 0 && val.indexValue < trigger;

      const items = acc[measurement.variable?.name] ?? [];
      const isHighlighted = !!highlightedPoints?.find(
        (highlightedPoint) => highlightedPoint?.timestampUtc === val?.timestampUtc,
      );

      const isInPolygon = pointsInPolygon.includes(val.timestampUtc);

      items.push({
        measurement,
        feature,
        x: new Date(val.timestampUtc).getTime(),
        y: measurement?.value,
        unit: plural(measurement?.variable?.unit.id,measurement?.value),
        color: isHighlighted
          ? BLUE_COLOR
          : isRed
          ? PRIMARY_COLOR
          : isOrange
          ? ORANGE_COLOR
          : isGreen
          ? GREEN_COLOR
          : isInPolygon
          ? SECONDARY_COLOR
          : GRAY_COLOR,
        timestampUtc: val.timestampUtc,
      });

      acc[measurement?.variable?.name] = items;
    });

    return acc;
  }, {});

  const clearHoverState = (timestampUtc, map) => {
    if(!transformedData){
      return;
    }
    
    Object.values(transformedData).forEach((items) => {
      items.forEach((item) => {
        if (item.timestampUtc !== timestampUtc) {
          return;
        }

        const featureId = timestampUtc + item?.feature?.properties?.referenceId;
        const segmentId = item?.feature?.properties?.segmentId;
        const feature = { source: featureId, id: segmentId };
        setHighlightedPoints(null);
        map.setFeatureState(feature, { hover: false });
      });
    });
  };

  const setHoverState = (timestampUtc, map) => {
    const highlightedPoints = [];

    Object.values(transformedData).forEach((items) => {
      items.forEach((item) => {
        if (item.timestampUtc !== timestampUtc) {
          return;
        }

        const featureId = timestampUtc + item.feature?.properties?.referenceId;
        const segmentId = item.feature?.properties?.segmentId;
        const feature = { source: featureId, id: segmentId };
        map.setFeatureState(feature, { hover: true });
        highlightedPoints.push({ ...item.feature, timestampUtc });
      });
    });

    setHighlightedPoints(highlightedPoints);

    if (highlightedPoints.length > 0) {
      map.flyTo({ center: highlightedPoints[0]?.geometry?.coordinates, duration: 300 });
    }
  };

  const handleMouseLeave = () => {
    mouseOutRef.current = setTimeout(() => {
      const map = maplibreRef.current;

      if (!map) {
        return;
      }

      clearHoverState(pointRef.current, map);
      pointRef.current = null;
    }, 500);
  };

  const handleMouseEnter = () => {
    if (mouseOutRef.current) {
      clearTimeout(mouseOutRef.current);
    }
  };

  useEffect(() => {
    if (!mapContainer.current) {
      return;
    }

    const mapStyle = "https://maps.geoapify.com/v1/styles/toner-grey/style.json";
    const apiKey = window.appConfig.geoapify.api.key;

    const map = new maplibre.Map({
      container: mapContainer.current,
      style: `${mapStyle}?apiKey=${apiKey}`,
      zoom: 8,
      interactive: true,
    });

    const coordinates = data?.reduce(
      (acc, val) => acc.concat(val.features?.features?.map((item) => item?.geometry?.coordinates)),
      [],
    );

    const popupList = [];
    const LINE_ID = 1;

    map.on("load", () => {
      setIsMapLoaded(true);

      map.addSource("line", {
        type: "geojson",
        data: {
          type: "Feature",
          id: LINE_ID,
          geometry: {
            type: "LineString",
            coordinates,
          },
        },
      });

      map.addLayer({
        id: "line",
        type: "line",
        source: "line",
        layout: {
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          "line-color": [
            "case",
            ["boolean", ["feature-state", "clicked"], false],
            "#ef4444",
            "#3b82f6",
          ],
          "line-width": 4,
        },
      });

      data?.forEach((item) => {
        const id = item.timestampUtc;
        const popup = new maplibre.Popup({
          closeButton: true,
          closeOnClick: false,
        });
        popupList.push(popup);

        item.features.features.forEach((feature) => {
          const isRed = item.payout > 0;
          const isOrange = item.payout === 0 && feature.properties?.intersected === 1;
          const isGreen = item.payout === 0 && item.indexValue < trigger;
          const featureId = id + feature.properties?.referenceId;
          const segmentId = feature.properties?.segmentId;
          const measurement = item.measurements.find(
            (item) => item.referenceId === feature.properties?.referenceId,
          );
          const coordinates = feature.geometry?.coordinates;
          let isClicked = false;
          let isHovered = false;

          map.addSource(featureId, {
            type: "geojson",
            data: {
              id: segmentId,
              type: "Feature",
              properties: {
                measurement,
              },
              geometry: feature.geometry,
            },
          });

          map.addLayer({
            id: featureId,
            type: "circle",
            source: featureId,
            paint: {
              "circle-color": [
                "case",
                ["boolean", ["feature-state", "hover"], false],
                BLUE_COLOR,
                ["boolean", isRed],
                PRIMARY_COLOR,
                ["boolean", isOrange],
                ORANGE_COLOR,
                ["boolean", isGreen],
                GREEN_COLOR,
                ["boolean", ["feature-state", "contain"], false],
                SECONDARY_COLOR,
                GRAY_COLOR,
              ],
              "circle-stroke-width": 1,
              "circle-radius": 6,
            },
          });

          const createPopup = () => {
            popup
              .setLngLat(coordinates)
              .setHTML(
                `
          <div class="${classes.popup}">
            <div class="${classes.date}">
              <span>${dayjs(id).format("DD MMM YYYY")}</span>
            </div>
            <div class="${classes.coordinates}">
              <span>(-${coordinates[0].toFixed(2)}°,</span>
              <span>-${coordinates[1].toFixed(2)}°)</span>
            </div>
            <div class="${classes.measurement}">
                ${measurement?.value} ${plural(measurement?.variable?.unit?.symbol,measurement?.value)}
            </div>
          </div>
          `,
              )
              .addTo(map);
          };

          popup.on("close", () => {
            if (!isHovered) {
              isClicked = false;
            }
          });

          map.on("mouseenter", featureId, (e) => {
            map.getCanvas().style.cursor = "pointer";
            setHighlightedPoints([{ ...feature, timestampUtc: item.timestampUtc }]);

            if (e.features.length > 0) {
              const feature = { source: featureId, id: segmentId };
              map.setFeatureState(feature, { hover: true });
            }
            isHovered = true;
            createPopup();
          });

          map.on("mouseleave", featureId, () => {
            map.getCanvas().style.cursor = "";
            const feature = { source: featureId, id: segmentId };
            map.setFeatureState(feature, { hover: false });
            setHighlightedPoints(null);
            isHovered = false;

            if (!isClicked) {
              popup.remove();
            }
          });

          map.on("click", featureId, (e) => {
            e.preventDefault();
            createPopup();
            isClicked = !isClicked;
          });
        });
      });

      map.on("mouseenter", "line", () => {
        map.getCanvas().style.cursor = "pointer";
      });

      map.on("mouseleave", "line", () => {
        map.getCanvas().style.cursor = "";
      });
    });

    maplibreRef.current = map;

    return () => {
      setIsMapLoaded(false);
      map.remove?.();
      popupList.forEach((popup) => {
        popup.remove();
      });
    };
  }, [data, classes, trigger]);

  useEffect(() => {
    const CIRCLE_LAYER_ID = "CIRCLE_AROUND_POINT";
    const CIRCLE_BACKGROUND_LAYER_ID = "CIRCLE_BACKGROUND_POINT";
    const map = maplibreRef.current;

    if (!map || !isMapLoaded || !monitoringDataCatalog) {
      return;
    }

    map.on("styledata", () => {
      const oldLayer = map.getLayer(CIRCLE_LAYER_ID);

      if (oldLayer) {
        return;
      }

      const pointsInPolygon = [];
      const type = monitoringDataCatalog.geometryData.geoJson.features[0].geometry?.type;
      let coordinates =
        monitoringDataCatalog.geometryData.geoJson.features[0].geometry?.coordinates;
      if (type === "MultiPolygon") {
        coordinates = coordinates[0][0][0];
      }
      if (type === "Polygon") {
        coordinates = coordinates[0][0];
      }
      const _circle = circle(coordinates, radius);
      const _polygon = polygon(_circle.geometry?.coordinates);
      map.flyTo({ center: coordinates, zoom: 9, duration: 0 });

      data.forEach((item) => {
        item.features.features.forEach((feat) => {
          const featureId = item.timestampUtc + feat.properties?.referenceId;
          const segmentId = feat.properties?.segmentId;
          const _point = point(feat.geometry?.coordinates);
          const isIn = booleanPointInPolygon(_point, _polygon);
          const feature = { source: featureId, id: segmentId };

          if (isIn) {
            map.setFeatureState(feature, { contain: true });
            pointsInPolygon.push(item.timestampUtc);
          }
        });
      });

      map.addLayer({
        id: CIRCLE_BACKGROUND_LAYER_ID,
        type: "fill",
        source: {
          type: "geojson",
          data: _polygon,
        },
        paint: {
          "fill-color": theme.palette.roseFog,
          "fill-opacity": 0.6,
        },
      });

      map.addLayer({
        id: CIRCLE_LAYER_ID,
        type: "line",
        source: {
          type: "geojson",
          data: _circle,
        },
        paint: {
          "line-color": "#dc2626",
          "line-width": 2,
        },
      });

      if (pointsInPolygon.length > 0) {
        setPointsInPolygon(pointsInPolygon);
      }
    });
  }, [highlightedPoints, isMapLoaded, data, monitoringDataCatalog, radius, theme.palette.roseFog]);

  return (
    <div className="footprint-chart">
      <div className={clsx(classes.map, isMapLoaded && classes.mapShow)} ref={mapContainer} />
      <div onMouseLeave={handleMouseLeave} onMouseEnter={handleMouseEnter}>
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            chart: {
              type: "column",
              height: 400,
            },
            title: {
              text: title,
              align: "left",
              style: {
                fontWeight: 400,
                fontSize: 10,
              },
            },
            plotOptions: {
              column: {
                pointWidth: 9,
                borderRadiusTopLeft: "50%",
                borderRadiusTopRight: "50%",
                borderRadiusBottomLeft: 0,
                borderRadiusBottomRight: 0
              },
              series: {
                minPointLength: 2,
                dataLabels: {
                  enabled: true,
                  zIndex: 11,
                },
                point: {
                  events: {
                    mouseOver: function (e) {
                      const map = maplibreRef.current;

                      if (pointRef.current === e.target.timestampUtc || !map) {
                        return;
                      }

                      if (pointRef.current !== null) {
                        clearHoverState(pointRef.current, map);
                      }

                      pointRef.current = e.target.timestampUtc;
                      setHoverState(pointRef.current, map);
                    },
                  },
                },
              },
            },
            xAxis: {
              type: "datetime",
              gridLineWidth: 0,
              crosshair: true,
              lineWidth: 0,
              tickWidth: 0,
              tickInterval: 24 * 3600 * 1000, // Tick interval of one day
              labels: {
                formatter: function () {
                  let isTriggered = false;

                  Object.values(transformedData).forEach((item) => {
                    const value = item.find((item) => item.x === this.pos);

                    if (value === undefined) {
                      return;
                    }

                    if (isTriggered) {
                      return;
                    }

                    if (value.y >= trigger) {
                      isTriggered = true;
                    }
                  });

                  const currentDate = dayjs(this.pos).format("DD/MM");

                  return `<span ${isTriggered ? `style="color: red"` : ""}>${currentDate}</span>`;
                },
              },
            },
            legend: {
              enabled: true,
              symbolHeight: 0,
              x: 5,
            },
            yAxis: {
              title: {
                enabled: true,
                text: title,
              },
              labels: {
                style: {
                  color: "#000",
                  fontWeight: 400,
                  fontSize: 12,
                },
              },
              plotLines: [
                {
                  value: trigger ?? null,
                  color: theme.palette.indicatorRed,
                  width: 1,
                  zIndex: 10,
                  dashStyle: "longdashdot",
                },
                exit && {
                  value: exit,
                  color: theme.palette.indicatorRed,
                  width: 1,
                  zIndex: 10,
                  dashStyle: "longdashdot",
                },
              ].filter(Boolean),
            },
            series: transformedData ? Object?.entries(transformedData)?.map(([key, val]) => ({
              name: key,
              data: val,
              states: {
                hover: {
                  color: BLUE_COLOR,
                },
              },
            })) : null,
            tooltip: {
              shared: true,
              outside: true,
              formatter: function () {
                return [
                  `<span style="font-size: 0.8em">${dayjs(this.x).format(
                    "dddd, DD MMM YYYY",
                  )}</span><br/>`,
                  `Windspeed: <b>${this.y} ${this.points?.[0]?.point?.unit}</b>`,
                  "",
                ];
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export const FootprintChart = ({
  data,
  isLoading,
  riskTypeId,
  name,
  trigger,
  exit,
  unit,
  lowerChartTitle,
  minPayout,
  payoutPerUnit,
  triggers,
  monitoringDataCatalog,
  radius,
  triggerComporator
}) => {
  const minRateValue = parseInt(trigger * 100) / 100;
  const maxRateValue = exit ? exit : payoutPerUnit ?
    parseInt((trigger + (100 - (minPayout?.amount ?? 0)) / (payoutPerUnit?.amount ?? 1)) * 100) /
    100: null;

  const sortedData = useMemo(
    () => data?.sort((a, b) => dayjs(a.timestampUtc).unix() - dayjs(b.timestampUtc).unix()),
    [data],
  );

  const classes = useStyles();
  const [container, setContainer] = useState(null);
  const screenWidth = useWindowResize();
  const { isOpen } = useSidebarContext();
  const theme = useTheme();
  const drawerWidth = isOpen ? theme.drawerWidth : 0;
  const containerWidth = useMemo(() => screenWidth - drawerWidth - parseInt(theme.spacing(12).replace('px', '')), [screenWidth, drawerWidth])

  const transformedFootprints = useMemo(
    () =>
      sortedData?.map((item) => ({
        x: new Date(item.timestampUtc).getTime(),
        y: item.indexValue,
        marker: {
          enabled: false,
        },
        color:
          item.indexValue > trigger ? theme.palette.indicatorRed : theme.palette.indicatorGreen,
      })),
    [sortedData, theme.palette.indicatorGreen, theme.palette.indicatorRed, trigger],
  );

  const isTriggered = sortedData?.some((item) => item.indexValue >= trigger);

  const minDate = Math.min(...transformedFootprints?.map(({x}) => x));
  const maxDate = Math.max(...transformedFootprints?.map(({x}) => x));
  const days = Math.ceil((maxDate - minDate) / (24 * 3600 * 1000));
  const MAX_DAYS = 90;
  const chartWidth = containerWidth * days / MAX_DAYS;

  const highChartConfig = {
    chart: {
      type: "area",
      height: 600,
      scrollablePlotArea: {
        minWidth: chartWidth,
        scrollPositionX: 0
      }
    },
    plotOptions: {
      area: {
        states: {
          hover: {
            lineWidth: 2,
            lineWidthPlus: 1,
          },
        },
      },
      series: {
        zones: [{
            value: trigger,
            fillColor: triggerComporator === 'GT' ? theme.palette.greenThumb : theme.palette.roseFog,
            color: triggerComporator === 'GT' ? theme.palette.indicatorGreen : theme.palette.indicatorRed,
        }, {
            fillColor: triggerComporator === 'GT' ?  theme.palette.roseFog : theme.palette.greenThumb,
            color: triggerComporator === 'GT' ?  theme.palette.indicatorRed : theme.palette.indicatorGreen,
        }],
      }
    },
    title: {
      text: name,
      align: "left",
      style: {
        fontWeight: 700,
        fontSize: 18,
      },
    },
    xAxis: {
      type: "datetime",
      gridLineColor: theme.palette.mercury,
      gridLineWidth: 1,
      crosshair: true,
      events: {
        setExtremes: syncExtremes,
      },
      lineWidth: 0,
      tickWidth: 0,
      tickInterval: 24 * 3600 * 1000, // Tick interval of one day
      labels: {
        format: "{value:%d/%m}",
        rotation: -90,
      },
      width: "97%",
      left: "1.5%",
    },
    yAxis: {
      gridLineColor: theme.palette.mercury,
      gridLineWidth: 1,
      title: {
        enabled: false,
      },
      labels: {
        style: {
          color: theme.palette.liver,
          fontWeight: 400,
          fontSize: 12,
        },
      },
      minRange: isTriggered ? exit : trigger,
      min: 0,
      plotLines: [
        {
          value: trigger > 0 ? trigger : null,
          color: theme.palette.indicatorGreen,
          width: 3,
          label: {
            text: `${
              formatMissingValue(minRateValue) + " " + (plural(convertUnit(unit),minRateValue) ?? "")
            } pays ${formatMissingValue(parseInt(minPayout?.amount * 100) / 100)}%`,
            align: "right",
            useHTML: true,
            style: {
              backgroundColor: theme.palette.indicatorGreen,
              padding: "10px 20px",
              borderRadius: "999px",
              transform: "translateX(-60px)",
              color: "#fff",
            },
          },
          zIndex: 10,
        },
        {
          value: maxRateValue > 0  ? maxRateValue : null,
          color: theme.palette.indicatorRed,
          width: 3,
          label: {
            text: `${formatMissingValue(maxRateValue) + " " + (plural(convertUnit(unit),maxRateValue) ?? "")} pays 100%`,
            align: "right",
            useHTML: true,
            style: {
              backgroundColor: theme.palette.indicatorRed,
              padding: "10px 20px",
              borderRadius: "999px",
              transform: "translateX(-60px)",
              color: "#fff",
            },
          },
          zIndex: 10,
        },
      ],
    },
    tooltip: {
      crosshairs: true,
      shared: true,
    },
    series: [
      {
        name: "Index Value",
        data: transformedFootprints,
        color: isTriggered ? theme.palette.indicatorRed : theme.palette.indicatorGreen,
      },
    ],
  };

  useEffect(() => {
    if (!container || isLoading || riskTypeId === "TC") {
      return;
    }

    const syncEventHandler = (e) => {
      let chart, point, i, event;

      for (i = 0; i < Highcharts.charts.length; i = i + 1) {
        chart = Highcharts.charts[i];

        if (!chart) {
          continue;
        }

        event = chart.pointer.normalize(e);
        point = chart.series[0].searchPoint(event, true);

        if (point) {
          point.highlight(e);
        }
      }
    };

    SYNC_EVENTS.forEach((eventType) => {
      container.addEventListener(eventType, syncEventHandler);
    });

    return () => {
      SYNC_EVENTS.forEach((eventType) =>
        container?.removeEventListener(eventType, syncEventHandler),
      );
    };
  }, [isLoading, container, riskTypeId]);

  return (
    <Box className={classes.root}>
      {isLoading ? (
        <Stack alignItems="center" justifyContent="center" minHeight={160}>
          <CircularProgress />
        </Stack>
      ) : (
        <Box ref={setContainer} sx={{ width: containerWidth }}>
          {riskTypeId !== "TC" && (
            <HighchartsReact highcharts={Highcharts} options={highChartConfig} />
          )}
          {riskTypeId === "TEMPERATURE" && (
            <TemperatureChart data={sortedData} triggers={triggers} />
          )}
          {riskTypeId === "RAINFALL" && (
            <RainfallChart data={sortedData} title={lowerChartTitle} triggers={triggers} containerWidth={containerWidth} />
          )}
          {riskTypeId === "TC" && (
            <WindfallChart
              title={lowerChartTitle}
              data={sortedData}
              monitoringDataCatalog={monitoringDataCatalog}
              radius={radius}
              trigger={trigger}
              exit={exit}
            />
          )}
        </Box>
      )}
    </Box>
  );
};
